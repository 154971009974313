import React, { useState } from 'react';
import { Radio, Tabs } from 'antd';

import Base from './main_segments/Base';
import Direccion from './main_segments/Direccion';
import Tools from './main_segments/Tools';

const App = (props) => {
  console.log(props);
  const [mode, setMode] = useState('top');
  const handleModeChange = (e) => {
    setMode(e.target.value);
  };

  let tabItems=[ 
    {
        label: "Principal",
        key: "principal",
        children: <Base/>,
    },
    {
        label: "Dirección",
        key: "direccion",
        children: <Direccion/>,
    }            
  ];

  const profile = props.profileInfo;
  const role_ids = profile.account_info.role_ids;
  if (role_ids.indexOf("vocal")>-1 || role_ids.join(".").toLowerCase().indexOf("eventos")>-1 ) {
    tabItems.push({
      label: "Herramientas",
      key: "tools",
      children: <Tools/>,
    });
  }


  return (
    <div>
      <Tabs
        defaultActiveKey="1"
        tabPosition="left"
        style={{
          height: 220,
        }}
        items={tabItems}
      />
    </div>
  );
};
export default App;