import { Card } from 'antd';
import { Col, Divider, Row } from 'antd';
import { Spin } from 'antd';
import { Button, Modal } from 'antd';
import { InputNumber, Slider, Space } from 'antd';


import { getUrlConfig } from '../../../config/url';

import { useEffect, useState } from 'react';

import {LoginTokenStore} from "../../../store/local";

import { PDFReader } from 'reactjs-pdf-view';
import { Pagination } from 'antd';



const OMNIA = () => {

  const [data, setData] = useState([]);
  const [omnia, setOmnia] = useState({id:"", png_base64: "", pdf_base64:""});
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [zoomValue, setZoomValue] = useState(100);

  const [tableParams, setTableParams] = useState({
    order: "asc"
  });


  // This is for the OMNIAs
  const [currentOmniaPage, setOmniaPage] = useState(1);
  const [totalOmniaPages, setOmniaPages] = useState(1);


  // This is for the PDFs
  const [numPages, setNumPages] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);


  const fetchData = (page) => {
    if (!page) { page=1; }
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/omnias/?page=`+page,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        let results=data.results;

        setData(results);
        setOmniaPages(data.total_pages);
        setLoading(false);

      });
  };

  const fetchSingle = (id, callback) => {
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/omnias/${id}/`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        let results=data;
      
        window.modalPNG=results.png_base64;
        window.modalPDF=results.pdf_base64;
        
//        console.log(atob(results.pdf_base64));
        setOmnia(results);
        setLoading(false);
        setTimeout(
          ()=> {callback()}
        ,150);

      });
  };  

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams)]);


  if(loading ){
    return (<Spin size="large" style={{float: "left", marginLeft:"50%", marginTop: "10%"}}/>)
  }

  let window_height=window.innerHeight*0.8;
  return (
    <section style={{backgroundColor: "rgb(240, 242, 245)", padding: 20}}>

      <Modal
        title=<Row>
              <Col span={8}><small>{window.modalTitle}</small></Col>
              <Col span={16}>
                  <Pagination simple size="small" 
                      defaultCurrent={1} 
                      pageSize={1}
                      total={numPages} showSizeChanger={false} 
                      onChange={(page)=>{setPageNumber(page)}}/>
              </Col>
              <Col span={4}></Col></Row>
        footer=<Row>
              <Col span={4}>
                Zoom:
              </Col>
              <Col span={8}>
                <center>
                  <Slider
                    min={20}
                    max={250}
                    step={10}
                    onChange={(value=>{setZoomValue(value); })}
                    value={typeof zoomValue === 'number' ? zoomValue : 0}
                  />                      
                </center>
              </Col>
            </Row>
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        width="95%"
        bodyStyle={{height: window_height, padding: 0, margin: 0}}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
      
      
          <div style={{overflowY:'scroll', overflowX: "scroll", height:window_height}}>
              <PDFReader 
                  url={`data:application/pdf;base64,${window.modalPDF}`}
                  page={pageNumber}
                  showAllPage={false}
                  scale={zoomValue/100}
                  onDocumentComplete={(info)=>{                    
                    setNumPages(parseInt(info));
                  }}
              />
           </div>
             
      </Modal>
      

      <center>
        <Pagination size="small" 
          defaultCurrent={currentOmniaPage} 
          pageSize={1}
          total={totalOmniaPages} showSizeChanger={false} 
          onChange={(page)=>{
                setOmniaPage(page)
                fetchData(page);
          }
        }
        />
      </center>

      <Row gutter={[16, 16]}>
      {        
        data.map((item,index)=>{
          return (
            <Col xs={12} sm={10} md={8} lg={6} xl={4} key={`card_${index}`} >
            <a href="#" onClick={()=>{ 
                fetchSingle(item.id, ()=>{
                    window.modalTitle=`OMNIA ${item.id}`;
                    setOpen(true);
                  });
                }}>
              <Card 
                  title=<center>{`${item.id}`}</center>
                  bordered={false}
                  style={{
                    width: "100%",
                    marginTop: "8px",
                    padding: "8px",
                  }}                  
                >
                  <div>
                    <center><img src={`data:image/jpeg;base64, ${item.png_base64}`} style={{maxWidth: "100%", maxHeight: "200px"}}/></center>
                  </div>
                </Card>          
              </a>
            </Col>
          )
        })
      }
      </Row>
        
    </section>      
  );
};
export default OMNIA;