import { useLocation, useParams } from "react-router-dom"

import { getUrlConfig } from '../../../config/url';

import { useEffect, useState } from 'react';

import { FacebookOutlined, LinkOutlined , UserOutlined } from '@ant-design/icons';
import {LoginTokenStore} from "../../../store/local";

const InfoPage = (props) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  let path = useLocation().pathname;
  let post_page_url = "/../../blog/";
  
  // What should we show??
  if (path=="/faq") {
    post_page_url = post_page_url + "preguntas-frecuentes/"
  }

  console.log(post_page_url);

  const fetchData = () => {
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}${post_page_url}`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    )
      .then((res) => res.text())
      .then((data) => {
        let results=data;

        setData(results);
        setLoading(false);                

      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <section style={{backgroundColor: "rgb(240, 242, 245)", padding: 20}}>
      
      <div dangerouslySetInnerHTML={{__html: data }}></div>

    </section>      
  );
};
export default InfoPage;