import { Button, Form, Input } from 'antd';
import { LayoutForm_wide, LayoutFormButton } from '../../../config/layout';

interface ProfileRepassUIProps {
  onFinishCallback: (values: any) => void;
}

/**
 * 修改密码 UI 组件
 * @param props
 * @returns
 */
const ProfileRepassUI = (props: ProfileRepassUIProps) => {
  const [form] = Form.useForm();
  return (
    <div className="panel-body">
      <Form {...LayoutForm_wide} name="basic" form={form} onFinish={props.onFinishCallback}>
        <Form.Item
          label="Contraseña anterior"
          name="old_pwd"
          rules={[{ required: true, message: '¡Debes indicar la contraseña anterior!' }]}
        >
          <Input.Password placeholder="" />
        </Form.Item>

        <Form.Item
          label="Nueva contraseña"
          name="new_pwd"
          rules={[{ required: true, message: 'Indica la NUEVA contraseña' }]}
        >
          <Input.Password placeholder="" />
        </Form.Item>

        <Form.Item
          label="Confirmar contraseña"
          name="confirm_pwd"
          rules={[{ required: true, message: 'Confirma la contraseña' }]}
        >
          <Input.Password placeholder="" />
        </Form.Item>

        <Form.Item {...LayoutFormButton}>
          <Button type="primary" htmlType="submit">
            Enviar
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default ProfileRepassUI;
