import React, { useCallback, useState, useEffect } from 'react';

import _ from 'lodash'

import { Row, Col, Form, Popover } from 'antd';

import { Layout} from 'antd';

import { Steps, Button } from 'antd';

import { Input, Card, Space } from 'antd';
import { InputNumber } from 'antd';

import { Slider } from 'antd';

import { Switch } from 'antd';

import { Select } from 'antd';

import { RightCircleOutlined } from '@ant-design/icons';
import { LeftCircleOutlined } from '@ant-design/icons';


const { TextArea } = Input;
const { Header, Footer, Sider, Content } = Layout;

const origin = {}

//----------------------------------------

const handleMessageSubstitutions=(message, item_index)=>{
    message=message.replaceAll("{index}"," "+(item_index+1));

    return message;
};

const showValuesSummary=(values)=>{
    console.log(values);
    return "ASDASD";
}

const decodeItem=(item, item_index, parent_id, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui,extra_info)=>{
    
    let labelCol = { xs: { span: 24 }, sm: { span: 8 }, md: { span: 8 }, lg: { span: 8 } };
    let wrapperCol = { xs: { span: 24 }, sm: { span: 16 }, md: { span: 16 }, lg: { span: 16 } };

    const required_mark = <span> (<span style={{color: "red"}}>*</span>)</span>;
    const show_available_popover_content = (available)=>{
        if (!available) return (
            <div>
              <p>No hay plazas disponibles</p>
            </div>
          );

        return (
            <div>
              <p>Plazas disponibles: {available}</p>
            </div>
        )
    
    }


    let to_return={};

    // --- MAIN 
    if (item.name=="main") {
        let key=parent_id+"main";
        let step_list=[];
        
        item.children.map( (child, child_index)=>{
            const sub_child=decodeItem(child, child_index, key, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui, extra_info);
            step_list.push({
                "title": child.label                
            });
            set_step_content(child_index, sub_child);        
        });

        step_list.push({
            "title": "Resumen",
        });
        set_step_content(item.children.length, 
                <div>
                    {showValuesSummary(extra_info.values)}
                </div>
            ); 
                
        return <div>

                <div>
                    <Steps
                        key={`step_${item_index}`}
                        size="small"
                        current={current_step}
                        items={step_list}
                    />
                </div>                    
                <div style={{ maxHeight: 'calc(100vh - 100px)' }}>
                    <div style={{overflowY: "auto", maxHeight: 'calc(100vh - 200px)' }}>
                            {steps[current_step]}
                    </div>            
                </div>        

                <div>
                        {current_step>0 &&
                            <Button  disabled={extra_info.required_condition_not_met} onClick={()=>{ if(current_step>0){ set_current_step(current_step-1)} }} style={{ height: "40px", verticalAlign: "bottom", float: "left"}}>  
                                <LeftCircleOutlined style={{fontSize: "25px"}}  /> 
                                <span style={{verticalAlign: "text-bottom"}}> Atrás </span>                
                            </Button>                
                        }


                        <h3 style={{marginLeft: "50%", float: "left"}}>
                            <>
                                Total: {extra_info.cost}€  
                            </>
                        </h3>

                        <Button disabled={extra_info.required_condition_not_met} type="primary" onClick={()=>{ 
                                set_current_step(current_step+1);
                            }} style={{ height: "40px", verticalAlign: "bottom", float: "right"}}> 
                            <span style={{verticalAlign: "text-bottom"}}> Continuar </span>                
                            <RightCircleOutlined style={{fontSize: "25px"}}  />  
                        </Button>
                </div>

                </div>;
    
    // --- STEP
    } else if (item.name=="step") {                
        let key=parent_id+".step"+item_index;
        to_return=[];
        item.children.map( (child, child_index)=>{
            const temp=decodeItem(child, child_index, key, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui, extra_info);
            to_return.push(temp);
        });        


        return to_return;

    // -- SEPARATOR
    } else if(item.name=="separator"){
        return <hr/>

    // -- AMOUNT
    } else if(item.name=="amount"){
        let key=parent_id+".amount"+item_index;
        let children=[];
        
        let label=<span>            
            {item.required &&
                required_mark
            }
            {handleMessageSubstitutions(item.message, item_index)}
            </span>;
        if(item.required && !get_value(key)) {extra_info.required_condition_not_met=true;}
        if (get_value(key)) {
            extra_info.cost+=item.cost * get_value(key);
        }
        

        // Disabled due to no availability?
        let max_amount=item.max_amount;
        let disabled=false;
        let available=0;
        
        if (item.limit_field) {
            // array to dict
            let availability_dict=extra_info.availability_info;            

            available=availability_dict[item.limit_field].plazas;
            max_amount=Math.min(availability_dict[item.limit_field].plazas, item.max_amount);
            disabled=availability_dict[item.limit_field].plazas<1;

        }        

        for(let iterator=0;iterator<get_value(key);iterator++){
            item.children.map( (child, child_index)=>{
                const sub_child=decodeItem(child, child_index+iterator, key, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui, extra_info);
                children.push(sub_child);
            });
        }

        let col_span=24;
        return (<Row required={item.required}>
                    <Col span={col_span}>
                        <Form.Item
                            label={label}
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}                    
                            >
                            <Popover content={show_available_popover_content(available)} trigger={"hover"}>                                     
                                <Row>                                
                                        <Col span={6}><span style={{fontSize: "120%"}}>[ {get_value(key)?get_value(key):0} ]</span></Col>                                    
                                    <Col span={18}>                                    
                                            <Slider key={key} defaultValue={get_value(key)} max={max_amount} disabled={disabled} onAfterChange={(value=>{
                                                    set_value(key, value,handleMessageSubstitutions(item.message, item_index));
                                                    setTimeout(
                                                        ()=>{update_ui()}, 100
                                                    )                    
                                                })} />
                                        
                                    </Col>
                                </Row>
                            </Popover> 
                        </Form.Item>
                    </Col>

                <Col span={col_span}>
                    {children}
                </Col>
            </Row>
        )

    // -- SELECT
    } else if(item.name=="select"){        
        let key=parent_id+".select"+item_index;
        let options=[];
        let costs=item.prices;
        let limits=item.limits;
        let limits_disabled=[];

        // array to dict
        let availability_dict=extra_info.availability_info;            
        limits.map( (item,index)=>{
            if (limits[index]) {
                limits_disabled.push(
                    availability_dict[limits[index]].plazas<1
                )    
            } else {
                limits_disabled.push(false)
            }
        })

        item.options.split(",").map( (option,index)=>{
            options.push({
                "label": option,
                "value": index,
                "disabled": limits_disabled[index]
            });
        });

        let label=<span>            
            {item.required &&
                required_mark
            }
            {handleMessageSubstitutions(item.message, item_index)}
            </span>;

        if(item.required && get_value(key)==undefined) { extra_info.required_condition_not_met=true;}

        let children=[];

        if(get_value(key)){
            extra_info.cost+=costs[get_value(key)];
        }
        

        if(get_value(key)!==undefined){
            item.children[get_value(key)].map( (child, child_index)=>{
                const sub_child=decodeItem(child, child_index, key, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui, extra_info);
                children.push(sub_child);
            });    
        }

        let col_span=24;
        return (
            <div required={item.required}>
            <Row>
                <Col span={col_span}>
                    <Form.Item
                        label={label}
                        labelCol={labelCol}
                        wrapperCol={wrapperCol}                    
                        style={{textAlign: "left"}}       
                        >                
                        <Select
                            value={get_value(key)}
                            style={{
                                width: "50%",
                            }}
                            onChange={(value)=>{                                
                                set_value(key, value,handleMessageSubstitutions(item.message, item_index), options[value].label);
                                update_ui();
                            }}
                            options={options}
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                { children.length>0 &&
                    <Col span={col_span} style={{border: "1px solid #F5F5F5", borderRadius: "20px", backgroundColor: "#FAFAFA", marginLeft: "1%", padding: "1%"}}>
                        {children}
                    </Col>                
                }
            </Row>
            </div>
        )

    // -- CHECKBOX
    } else if(item.name=="checkbox"){
        let key=parent_id+".checkbox"+item_index;
        let message = item.message;
        let children=[];

        if(item.required && !get_value(key)) { extra_info.required_condition_not_met=true;}

        if(get_value(key)) {
            item.children.map( (child, child_index)=>{
                const sub_child=decodeItem(child, child_index, key, set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui, extra_info);
                children.push(sub_child);
            });                        
        }

        // Disabled due to no availability?
        let disabled=false;
        let available=0;
        if (item.limit_field) {
            let availability_dict=extra_info.availability_info;            

            available=availability_dict[item.limit_field].plazas;
            disabled=availability_dict[item.limit_field].plazas<1;
        }

        if (get_value(key)) {
            extra_info.cost+=item.cost * get_value(key);
        }

        message=handleMessageSubstitutions(message, item_index);

        
        let col_span=24;
        let label=<span>            
            {item.required &&
                required_mark
            }
            {message}
            {item.cost>0 &&
                <span>&nbsp;&nbsp;({item.cost}€)</span>
            }
            </span>;
                



        to_return= <Row required={item.required}  >
                        <Col span={col_span}>
                            <Form.Item
                                label={label}
                                labelCol={labelCol}
                                wrapperCol={wrapperCol}             
                                style={{textAlign: "left"}}       
                                >           
                                    <Popover content={show_available_popover_content(available)} trigger={item.limit_field?"hover":"none"}>                         
                                        <Switch checked={get_value(key)} disabled={disabled} onChange={(checked)=>{
                                            set_value(key, checked,handleMessageSubstitutions(item.message, item_index));
                                            update_ui();
                                        }}/>                                    
                                    </Popover>
                            </Form.Item>
                            </Col>
                            {
                                children.length>0 &&
                                <Col span={col_span} style={{border: "1px solid #F5F5F5", borderRadius: "20px", backgroundColor: "#FAFAFA", marginLeft: "1%", padding: "1%"}}>
                                    {children}
                                </Col>                                                                            
                            }

                    </Row>
        return to_return;

        
    // -- TEXT
    } else if(item.name=="text"){
        let key=parent_id+".text"+item_index;
        let col_span=24;
        let label=<span>            
            {item.required &&
                required_mark
            }
            {handleMessageSubstitutions(item.message, item_index)}
            </span>;
        
        let timeout_handle;

        to_return= <Row required={item.required}>
            <Col span={col_span}>
                <Form.Item
                    label={label}
                    labelCol={labelCol}
                    wrapperCol={wrapperCol}                    
                    >
                    <Input defaultValue={get_value(key)} onChange={(e)=>{
                                set_value(key, e.target.value,handleMessageSubstitutions(item.message, item_index));
                                // We need the timeout to handle the change
                                if(timeout_handle) { clearTimeout(timeout_handle)}
                                timeout_handle=setTimeout(
                                    ()=>{ update_ui()},
                                    200
                                );
                            }}/>
                    </Form.Item>
            </Col>

        </Row>
        return to_return;

    // -- MULTILINE TEXT
    } else if(item.name=="multiline_text"){
        let key=parent_id+".multilinetext"+item_index;
        let col_span=24;
        let timeout_handle;
        let label=<span>            
            {item.required &&
                required_mark
            }
            {handleMessageSubstitutions(item.message, item_index)}
            </span>;


        to_return= <Row required={item.required}>
                    <Col span={col_span}>
                        <Form.Item
                            labelCol={labelCol}
                            wrapperCol={wrapperCol}
                            label={label}
                            >
                            <TextArea rows={5} placeholder="" defaultValue={get_value(key)} onChange={(e)=>{
                                set_value(key, e.target.value,handleMessageSubstitutions(item.message, item_index));
                                // We need the timeout to handle the change
                                if(timeout_handle) { clearTimeout(timeout_handle)}
                                timeout_handle=setTimeout(
                                    ()=>{ update_ui()},
                                    200
                                );                                
                            }}/>;
                        </Form.Item>
                        </Col>
                    </Row>
        return to_return;

    // -- INFO
    } else if(item.name=="header"){
    let col_span=24;
    to_return=<Row>
                <Col span={col_span}>
                    
                    <h2 style={{textAlign: "left"}}>{handleMessageSubstitutions(item.message, item_index)}</h2>
                
                </Col>
                </Row>;
    return to_return;

    // -- INFO
    } else if(item.name=="info"){
        let col_span=24;
        to_return=<Row>
                    <Col span={col_span}>
                        
                        <p style={{textAlign: "left"}}  dangerouslySetInnerHTML={{__html: handleMessageSubstitutions(item.message, item_index) }}></p>
                    
                    </Col>
                    </Row>;
        return to_return;
    }

    return;
}



export default (props) => {

    const [appCounter, setAppCounter] = useState(0);

    const [current_step, set_current_step] = useState(0);

    const [values, setValues] = useState({});

    const [steps, setStepContent] = useState({});
    const [items, setItems] = useState([]);

    const [decoded, setDecoded] = useState(false);

    const set_step_content=(index,content)=>{
        let temp=steps;
        temp[index]=content;

        setStepContent(temp);
    };
    
    const get_value=(key)=>{
        return values[key];
    }

    const set_value=(key,value)=>{
        let temp=values;

        values[key]=value;
        setValues(temp);
    }    
    
    //const current_step = useFormStore((state) => state.current_step);
    //const set_current_step = useFormStore((state) => state.setCurrentStep);

    const update_ui=()=>{
        setAppCounter(appCounter+1);
    };

    let extra_info={
        cost: 0,
        required_condition_not_met:false,
        values: values,
        availability_info: props.availability_info
    }

    useEffect(() => {
        let temp=decodeItem(props.json_info,0,"", set_step_content, steps, current_step, set_current_step, get_value, set_value, update_ui,extra_info);        
        setItems(temp);
        props.setChildInfo(extra_info);
    }, [appCounter, current_step]);    
    
        
    console.log(values);
    
    return (
        <div>
            {items}
        </div>
    )    

}