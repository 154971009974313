import { Button, Form, Input } from 'antd';
import { AccountService } from '../../../../services/Account';

import { message, Space } from 'antd';


import { LayoutForm_wide, LayoutFormButton } from '../../../../config/layout';



/**
 * 个人信息 UI 组件
 * @param props
 * @returns
 */

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const cardStyle = {
  marginBottom: 16,
};


// --- Componente principal -----------------------------------------------------------------

const DireccionProfileInfoUI = (props) => {
  const [messageApi, contextHolder] = message.useMessage();

  const [form] = Form.useForm();

  const success = (message) => {
    messageApi.open({
      type: 'success',
      content: message,
    });
  };

  return (
    <div className="panel-body">

        {contextHolder}

        Este botón actualiza los ficheros CSS, JS de actividades...
        <br/>
        <br/><br/>        

        <Button type="primary" onClick={(e)=>{
                AccountService.saveTools()
                .then((data) => {
                    console.log(data);
                    success("Ok! En breve se actualizará el servidor...");
                })
                .catch(e => {
                  console.log(e);
                });
        }}>Actualizar ahora</Button>
    </div>
  );
};



export default DireccionProfileInfoUI;
