import { Button, Form, Input } from 'antd';

import {
  Alert,
  Avatar,
  Card,
  Col,
  Divider,
  List,
  Row,
  Space,
  Table,
  Timeline,
  Typography,
} from 'antd';



import { LayoutForm_wide, LayoutFormButton } from '../../../../config/layout';


const { Text, Link } = Typography;


/**
 * 个人信息 UI 组件
 * @param props
 * @returns
 */

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const cardStyle = {
  marginBottom: 16,
};


// --- Componente principal -----------------------------------------------------------------

const DireccionProfileInfoUI = (props) => {
  const [form] = Form.useForm();


  return (
    <div className="panel-body">

      <Form
        {...LayoutForm_wide}
        name="basic"
        form={form}
        onFinish={props.onFinishCallback}
        initialValues={props.profileInfo?.account_info}
      >

        <Form.Item
          label="Direccion"
          name="direccion"
          rules={[{ required: true, message: 'La dirección es necesaria!' }]}
        >
          <Input placeholder="Ejemplo: Calle xxx, portal xx,..." />
        </Form.Item>

        <Form.Item
          label="Población"
          name="poblacion"
          rules={[{ required: true, message: 'Indicar población' }]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Provincia" name="provincia">
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Código Postal" name="codigo_postal">
          <Input  />
        </Form.Item>

        <Form.Item label="País" name="pais">
          <Input  />
        </Form.Item>

        <Form.Item {...LayoutFormButton}>
            <center>
                <Button type="primary" htmlType="submit">
                    Actualizar
                </Button>

            </center>
        </Form.Item>
      </Form>
    </div>
  );
};



export default DireccionProfileInfoUI;
