import { Badge, Calendar } from 'antd';
import { getUrlConfig } from '../../../config/url';
import { useEffect, useState } from 'react';
import {LoginTokenStore} from "../../../store/local";
import { Button, Popover, Space } from 'antd';

import 'moment/locale/es';
import moment from "moment";

moment.locale("es", {
  week: {
    dow: 1
  }
});


const Actividades = () => {  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const fetchData = (month,year) => {
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/activities/?year=${year}&month=${month+1}`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        let results=data.results;
        setData(results);
        setLoading(false);

      });
  };  


  const getListData = (value) => {
    let date=value.date();

    if (data===undefined){ return []}
    if (data.length===undefined){ return []}

    let events= data.filter(element=> element.dia==date);
    
    if (events==undefined) { return [] }

    let event_list=[];

    events.map( (item,index)=>{
      event_list.push(
          {
            type: 'success',
            content: item.title,
            id: item.id
          }
      );
    });

    return event_list;    

  }


  useEffect(() => {
    fetchData((new Date()).getMonth(), (new Date()).getFullYear());
  }, []);


  const show_content=(value, listData) => {
    let to_return=[];
    listData.map( (item,index)=>{
      let event = data.find(element=>element.id==item.id);

      to_return.push(
          <div key={`event_key_${index}`}>
            <h3>{event.title}</h3>
            <span dangerouslySetInnerHTML={{__html: event.text}}></span>
            <hr/>
        </div>
      )
    });

    return to_return;
  }

  const dateCellRender = (value) => {
    const listData = getListData(value);    
    return (
      <Popover content={(value)=>{return show_content(value, listData)}} title="Eventos" trigger="click" overlayStyle={{
        width: "60%"
      }}>    
      <div style={{maxWidth:"100%"}}>
        {listData.length>0 &&
            <ul className="events">
            {listData.map((item) => (
                <li style={{listStyleType: "none"}}><Badge key={item.content} status={item.type} text={item.content}/></li>
            ))}
          </ul>        
          }      
      </div>
      </Popover>
    );
  };  


  return <section style={{backgroundColor: "rgb(240, 242, 245)", padding: 5, height: "100%"}}>
    <Calendar 
      dateCellRender={dateCellRender}
      onSelect={(a)=>{}}
      className="mensaCSS"
      onChange={(date)=>{ console.log(date); return false;}}    
      onPanelChange={(date)=>{
        let month=date.month();
        let year=date.year();
        fetchData(month,year);
      }}
      />
  </section>

}

export default Actividades;
