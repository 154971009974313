import React from 'react';
import { useRef } from 'react';

import { Alert, Space, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';


const antIcon = <LoadingOutlined style={{ fontSize: 96 }} spin />;
const CustomSpinner = () => <Spin indicator={antIcon} />


const Spinner = (props) => {
    const windowSize = useRef([window.innerWidth, window.innerHeight]);

    if(!props.loading) {
        return <></>
    }

    return(
        <div style={{
            width: `${windowSize.current[0]}px`, 
            height: `${windowSize.current[1]}px`, 
            backgroundColor: "lightgray",
            opacity: 0.3,
            position: "fixed",
            top: "0px",
            left: "0px",
            zIndex: 99999
            }}>
            <Space direction="vertical" style={{ marginLeft: `${windowSize.current[0]/2-48}px`,marginTop: `${windowSize.current[1]/2-48}px` }}>
                <CustomSpinner tip="Loading">
                <div className="content" />
                </CustomSpinner>
        
            </Space>        
        </div>
    )

};
export default Spinner;