

interface UrlConfig {
    proxyUrl: string
}

const devUrlConfig :UrlConfig = {
    proxyUrl: "http://192.168.1.194:5805/api/v1",
}

const proUrlConfig :UrlConfig = {
    proxyUrl: "https://api.mensa.es/api/v1",
}

/**
 * urlConfig
 * @constructor
 */
export function getUrlConfig() :UrlConfig {
    if (process.env.NODE_ENV === 'development') {
        return devUrlConfig
    }
    return proUrlConfig
}