import { Badge, Calendar } from 'antd';
import { getUrlConfig } from '../../../config/url';
import { useEffect, useState } from 'react';
import {LoginTokenStore} from "../../../store/local";

import { Modal } from 'antd';

import { Card, Row, Col, Button } from 'antd';

import {PlusCircleOutlined} from '@ant-design/icons';

import Renderer from './element_renderer';

const Eventos = () => {  
  const [loading, setLoading] = useState(false);
  const [childInfo, setChildInfo] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [eventData, setEventData] = useState([]);

  const fetchData = (month,year) => {
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/events/?year=${year}&month=${month+1}`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {       
        let results=data.results;

        setEventData(results);
        setLoading(false);

      });
  };  


  const getListData = (value) => {
    let date=value.date();

    if (eventData===undefined){ return []}
    if (eventData.length===undefined){ return []}

    let events= eventData.filter(element=> element.dia==date);
    
    if (events==undefined) { return [] }

    let event_list=[];

    events.map( (item,index)=>{
      event_list.push(
          {
            type: 'success',
            content: item.title,
            id: item.id
          }
      );
    });

    return event_list;    

  }


  useEffect(() => {
    fetchData((new Date()).getMonth(), (new Date()).getFullYear());
  }, []);


  return <section style={{backgroundColor: "rgb(240, 242, 245)", padding: 5, height: "100%"}}>
    <Row gutter={6}>

      {
        eventData.map( (item,index)=>{
          return(
            <Col xs={{span: 24}} lg={{span: 12}}
            style={{marginTop: 6}}
              >
              <Card
                  title=<div>
                          <div style={{float: "left"}}>
                            {item.name}
                          </div>  
                          <div style={{float: "right"}}>
                            <small>[ Disponibilidad ]</small>
                          </div>                            
                        </div>
                  bordered={false}
                >

                  <Row>
                    <Col span={24}>
                      <img src={item.img_url} style={{width: "100%"}}
                      />
                    </Col>

                    <Col span={24}>
                      <p dangerouslySetInnerHTML={{__html: item.info}}></p>
                      <hr/>
                      <p>Inicio: {item.start_date}</p>
                    </Col>

                    <Col span={24}>
                      <Button type="primary" 
                          shape="round" style={{width: "100%"}} icon={<PlusCircleOutlined/>}
                          onClick={(e)=>{
                            setModalContent({"form": item.form_info, "availability": item.availability_info});
                            setOpenModal(true);
                          }}
                          > Apuntarme</Button>
                    </Col>

                  </Row>
                
            </Card>
            
          </Col>
          )
        })
      }

    
   </Row>  

      <Modal
          title={`Info Evento :: total a pagar ${childInfo.cost?childInfo.cost:0}€`}
          centered
          open={openModal}
          onOk={() => setOpenModal(false)}
          onCancel={() => setOpenModal(false)}
          footer={null}          
          width={"95%"}
          bodyStyle={{ maxHeight: 'calc(100vh - 100px)' }}
        >
          <div style={{padding: "10px"}}>
            <Renderer json_info={modalContent.form} availability_info={modalContent.availability} setChildInfo={(what)=>{
              console.log(what);
              setChildInfo(what);
            }}/>
          </div>
          
        </Modal>   
  </section>

}

export default Eventos;
