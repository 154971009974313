import React from 'react';
import { useNavigate } from "react-router-dom";

import {Result, Button} from "antd";

const Error404 = () => {
    
    let navigate = useNavigate(); 
    const routeChange = (where: any) =>{ 
      let path = where; 
      navigate(path);
    }

    return (
        <>
            <Result
                status="404"
                title="404"
                subTitle="Pues ya lo siento... no encuentro esa página por aquí"
                extra={<Button type="primary" onClick={()=>{
                    routeChange("/");
                }}>Volver</Button>}
            />
        </>
    );
}

export default Error404;