import { Button, Checkbox, Col, Form, Input, Row, Tabs } from 'antd';
import { Card, Space } from 'antd';

import { GithubOutlined, LikeOutlined, UserOutlined, LockOutlined } from '@ant-design/icons';
// @ts-ignore
import logoImg from '../../../assets/images/logo_mensa.png';
import './login.css';
const { TabPane } = Tabs;

interface LoginUIProps {
  systemLoginFinishCallback: (values: any) => void;
  domainLoginFinishCallback: (values: any) => void;
}

/**
 * Componentes de la "Form" de acceso al sistema
 * @param props
 * @returns
 */
const SystemLoginUI = (props: LoginUIProps) => {
  const [systemLoginForm] = Form.useForm();

  return (
    <Form
      name="system_login"
      className="login-form"
      initialValues={{ remember: true }}
      form={systemLoginForm}
      onFinish={props.systemLoginFinishCallback}
    >
      <Form.Item name="username" rules={[{ required: true, message: 'El dato de usuario es necesario' }]}>
        <Input
          size="large"
          prefix={<UserOutlined className="site-form-item-icon" />}
          placeholder="Usuario"
        />
      </Form.Item>
      <Form.Item name="password" rules={[{ required: true, message: 'La clave de acceso es necesaria' }]}>
        <Input
          size="large"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Clave"
        />
      </Form.Item>
      <Form.Item>
        <Form.Item name="remember" valuePropName="checked" noStyle>
          <Checkbox>Recordar</Checkbox>
        </Form.Item>
        <a className="login-form-forgot" href="/src/pages">
          {' '}
          ¿Olvidaste la contraseña？
        </a>
      </Form.Item>
      <Form.Item>
        <Button size="large" type="primary" htmlType="submit" className="login-form-button" block>
          Inicio
        </Button>
      </Form.Item>
    </Form>
  );
};


/**
 * Interfaz General
 * @param props 
 * @returns Componente de intefaz
 */
const LoginUI = (props: LoginUIProps) => {
  const items = [
    { label: 'Acceso al sistema', key: '1', children: <SystemLoginUI {...props} /> }
  ];

  return (
    <div className="login">
      <Card style={{ width: "90%", maxWidth: 600, margin: "auto" }}>
          <div className="login-header">
            <div className="login-title">
              <img src={logoImg} alt="logo"></img>
              <span>Mensa</span>
            </div>
            <p>(Área de socios)</p>
          </div>
          <div className="login-content">
            <Row justify="center">
              <Col span={12}>
                <Tabs defaultActiveKey="1" items={items} centered></Tabs>
              </Col>
            </Row>
          </div>
      </Card>
      <div className="login-footer">
        <p className="login-footer-recommed">Bienvenido a Mensa</p>
        <p className="login-footer-copyright">
          <a href="https://mensa.es" target="_black">
            Mensa
          </a>{' '}
          © 2023 Created by
          <a href="#" >
            {' '}
            QPSML
          </a>
        </p>
      </div>
    </div>
  );
};

export default LoginUI;
