import React, { Component } from 'react';
import { getUrlConfig } from '../../../config/url';
import {LoginTokenStore} from "../../../store/local";
import { Select } from 'antd';

import {message } from 'antd';

import { Button, Form, Input } from 'antd';
import { Tabs } from 'antd';
import { Radio } from 'antd';
import { Modal } from 'antd';
import { Popconfirm } from 'antd';

import { useEffect, useState } from 'react';


import { RiVisaFill } from 'react-icons/ri';
import { TbBrandMastercard } from 'react-icons/tb';
import { BsBank } from 'react-icons/bs';
import { TiTick } from 'react-icons/ti';
import { BsCreditCard2Back } from 'react-icons/bs';
import { VscCalendar } from 'react-icons/vsc';


import { setProfileAccountInfo } from '../../../store/local';
import { ProfileService } from '../../../services/Profile';


import LoadingSpinner from '../../Main/component/loading';


import {
  UnorderedListOutlined,
  LinkOutlined,
  HddOutlined,
  ContactsOutlined,
  ReadOutlined,
  DesktopOutlined,
  ChromeOutlined,
  CodeOutlined,
} from '@ant-design/icons';

import {
  Alert,
  Avatar,
  Card,
  Col,
  Divider,
  List,
  Row,
  Space,
  Table,
  Timeline,
  Typography,
} from 'antd';



const { Text, Link } = Typography;
const cardStyle = {
    marginBottom: 16,
  };
  

// ----- Componente pagos ------------------------------------------------------------------

function ValidateCreditCardNumber(ccNum) {

  var visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  var mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  var maestroRegEx = /^(5018|5020|5038|5893|6304|6759|6761|6762|6763)[0-9]{8,15}$/;
  var amexpRegEx = /^(?:3[47][0-9]{13})$/;
  var discovRegEx = /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/;
  var visamastercardEx = /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/;
  var isValid = false;

  if (visaRegEx.test(ccNum)) {
    isValid = "visa";
  } else if (mastercardRegEx.test(ccNum)) {
    isValid = "mastercard";
  } else if (maestroRegEx.test(ccNum)) {
    isValid = "maestro";
  } else if (amexpRegEx.test(ccNum)) {
    isValid = "amex";
  } else if (discovRegEx.test(ccNum)) {
    isValid = "discov";
  } else if (visamastercardEx.test(ccNum)) {
    isValid = "visamastercard";
  } else {
    return "";
  }

  return isValid;
}

function validateCardDate(date) {
  var dateRegEx = /^\d\d\/*\d\d$/;

  if (dateRegEx.test(date)) {    
    const info= date.replaceAll("/", "");
    const month=parseInt(info.slice(0,2));
    const year=parseInt(info.slice(2,4));

    if ((new Date(2000+year,month+1,1))<(new Date())) {  return ""; }

    return info;

  }
  return "";
}

function validateCardCVC(cvc) {
  var cvcRegEx = /^\d\d\d$/;

  if (cvcRegEx.test(cvc)) {
    return cvc;
  }
  return "";
}

function validateIBAN(iban) {
  var ibanRegEx = /^(?:(?:IT|SM)\d{2}[A-Z]\d{22}|CY\d{2}[A-Z]\d{23}|NL\d{2}[A-Z]{4}\d{10}|LV\d{2}[A-Z]{4}\d{13}|(?:BG|BH|GB|IE)\d{2}[A-Z]{4}\d{14}|GI\d{2}[A-Z]{4}\d{15}|RO\d{2}[A-Z]{4}\d{16}|KW\d{2}[A-Z]{4}\d{22}|MT\d{2}[A-Z]{4}\d{23}|NO\d{13}|(?:DK|FI|GL|FO)\d{16}|MK\d{17}|(?:AT|EE|KZ|LU|XK)\d{18}|(?:BA|HR|LI|CH|CR)\d{19}|(?:GE|DE|LT|ME|RS)\d{20}|IL\d{21}|(?:AD|CZ|ES|MD|SA)\d{22}|PT\d{23}|(?:BE|IS)\d{24}|(?:FR|MR|MC)\d{25}|(?:AL|DO|LB|PL)\d{26}|(?:AZ|HU)\d{27}|(?:GR|MU)\d{28})$/;

  if (ibanRegEx.test(iban)) {
    return iban;
  }
  return "";
}

function validateBankName(name) {
  var nameRegEx = /^[^\s]+\s+.+$/;

  if (nameRegEx.test(name)) {
    return name;
  }
  return "";
}


const PagosProfileInfoUI = (props) => {

  const [form] = Form.useForm();

  const [loadingSpinner, setLoadingSpinner] = useState(false);

  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);

  const [payMethod, setPayMethod] = useState("card");
  const [showCardModal, setShowCardModal] = useState(false);
  const [showIBANModal, setShowIBANModal] = useState(false);
  

  const [cardNumber, setCardNumber] = useState("");
  const [cardMMAA, setCardMMAA] = useState("");
  const [cardCVC, setCardCVC] = useState("");
  const [cardNumberOK, setCardNumberOK] = useState(false);
  const [cardDateOK, setCardDateOK] = useState(false);
  const [cardCVCOK, setCardCVCOK] = useState(false);
  const [cardModalOK, setCardModalOK] = useState(false);


  const [ibanNumber, setIbanNumber] = useState("");
  const [ibanHolder, setIbanHolder] = useState("");
  const [ibanNumberOK, setIbanNumberOK] = useState("");
  const [ibanHolderOK, setIbanHolderOK] = useState("");  
  const [sepaModalOK, setSepaModalOK] = useState(false);

  const [paymentHistory, setpaymentHistory] = useState([]);


  const getProfileInfo = () => {
    ProfileService.getProfileInfo()
      .then((profileInfo) => {
        setProfileAccountInfo(profileInfo.account_info);

        props.profileInfo.account_info.payment_info=profileInfo.account_info.payment_info;
      })
      .catch(e => {
        console.log('get profile catch: ', e);
      });
  };

  const sendPaymentData = (info) => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/profiles/me/update_payment_info/`,
      { method: "POST",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      },
      body: JSON.stringify(info)
    }    
    ).then((res) => res.json())
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        }
        console.log(data);
        getProfileInfo();
        setTimeout(
          ()=>{
            setLoadingSpinner(false);
            getProfileInfo();
          }, 4000
        )
      });
  };  

  const getPaymentHistory = () => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/profiles/me/get_transactions/`,
      { method: "GET",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        }
        console.log(data);
        setpaymentHistory(data.data);
        setLoadingSpinner(false);
      });
  };  
  

  const deletePaymentData = (payment_type) => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/profiles/me/update_payment_info/`,
      { method: "DELETE",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      },
      body: JSON.stringify({
        "type": payment_type
      })
    }    
    ).then((res) => res.json())
      .then((data) => {
        console.log(data);
        getProfileInfo();
        setTimeout(
          ()=>{
            setLoadingSpinner(false);
            getProfileInfo();
          }, 4000
        )

      });
  };  



  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 14 },
    },
  };

  let pay_select_options=[
    {
      value: null,
      label: 'Ninguna',
      disabled: true
    }];
  
  const all_cards = props.profileInfo.account_info.payment_info.cards;
  if(all_cards.data.length<1) {
    pay_select_options.push({
      value: 'card',
      label: 'Tarjeta',
      disabled: true
    });
  } else {
    pay_select_options.push({
      value: 'card',
      label: 'Tarjeta',      
    });
  }
  

  const all_sepa = props.profileInfo.account_info.payment_info.sepa_debit;
  if(all_sepa.data.length<1) {
    pay_select_options.push({
      value: 'sepa_debit',
      label: 'IBAN',
      disabled: true
    });
  } else {
    pay_select_options.push({
      value: 'sepa_debit',
      label: 'IBAN',      
    });
  }

  const default_payment_id=props.profileInfo.account_info.payment_info.default_payment;
  let default_payment=null;
  const days_since_subscription_expired=props.profileInfo.account_info.payment_info.days_since_subscription_started;
  const subscription_info=props.profileInfo.account_info.payment_info.subscription_info;


  const epoch2date=(what)=>{
    let date=(new Date(what*1000));

    return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear() + "  " + date.getHours() + ":" + date.getMinutes()
  }
  
  if (default_payment_id) {
    all_cards.data.map((card, index)=>{
      if (card.id==default_payment_id) { default_payment="card";  }
    })
    all_sepa.data.map((sepa, index)=>{
      if (sepa.id==default_payment_id) { default_payment="sepa_debit";  }
    })    
  }


  const [defaultPayment, setDefaultPayment] = useState(default_payment);

  let card = [];
  let sepa = [];

  if (all_cards !== undefined) { card = all_cards.data[0]; }
  if (all_sepa !== undefined) { sepa = all_sepa.data[0]; }

  function epoch_to_date(epoch){
    epoch=parseInt(epoch)*1000;

    let date=new Date(epoch);

    let meses=["Enero","Febrero","Marzo","Abril","Mayo","Junio","Julio","Agosto","Septiembre","Octubre","Noviembre","Diciembre"];

    let text=meses[date.getMonth()] + "-" + date.getFullYear();

    return text;
  }

  useEffect(
    ()=>{
      getPaymentHistory();
    }, []
  )

  
  return (
    <div className="panel-body">

      <LoadingSpinner loading={loadingSpinner}/>

        <Modal title=<span><VscCalendar />&nbsp;&nbsp;&nbsp;Confirmación de suscripción</span>
            open={showSubscriptionModal} 
            okButtonProps={{ style: {display: "none"} }}
            onOk={()=>{            
                
            }
            }
            onCancel={() => { setShowSubscriptionModal(false) }}>

            
            La suscripción asociada actualmente a mi cuenta es:
            <Card title={subscription_info.name}>
                <center>
                    '{subscription_info.name}' : ({epoch_to_date(subscription_info.interval[0])} ... {epoch_to_date(subscription_info.interval[1])}) 
                    <h3 fontSize="24px">{subscription_info.price}€</h3>

                  { !default_payment_id && 
                    <Button type="primary" disabled onClick={
                      ()=>{
                      }
                  }>No es posible confirmar sin un medio de pago por defecto</Button>                  
                  }

                  {
                    default_payment_id &&
                    <Button type="primary" onClick={
                      ()=>{
                          sendPaymentData({
                              "type": "confirm_subscription",
                              "subscription_id": subscription_info.stripe_id
                          });
                          setShowSubscriptionModal(false);
                      }
                  }>Confirmo la suscripción</Button>                    
                  }

                </center>
            </Card>


        
        </Modal>

      <Modal title=<span><BsCreditCard2Back />&nbsp;&nbsp;&nbsp;Datos de tarjeta</span>
        okButtonProps={{ disabled: !cardModalOK ? "disabled" : undefined }}
        open={showCardModal} 
        onOk={()=>{            
            sendPaymentData({
              "type": "card",
              "number": cardNumber,
              "mmaa": cardMMAA,
              "cvc": cardCVC
            });
              
            setShowCardModal(false);
          }
        }
        onCancel={() => { setShowCardModal(false) }}>

        

        <Form {...formItemLayout} style={{ maxWidth: 600 }}>
          <Form.Item
            label=<span>{cardNumberOK ? <TiTick style={{ color: "green" }} /> : ""} Número </span>
            validateTrigger="onBlur"
          >
            <Input
              placeholder="Número de tarjeta"
              onChange={(e) => {

                const isNumberValid = ValidateCreditCardNumber(e.target.value);

                setCardNumber(e.target.value);

                setCardNumberOK(isNumberValid != "");

                setCardModalOK( (isNumberValid!="" && cardDateOK!="" && cardCVCOK!="" ) );

                //this.props.setValue(value);
              }}
            />
          </Form.Item>

          <Form.Item label="MM/AA" style={{ marginBottom: 0 }}>
            <Form.Item
              style={{ display: 'inline-block', width: 'calc(50% - 20px)' }}
            >
              <Input placeholder="Fecha" onChange={(e)=>{
                const isDateValid = validateCardDate(e.target.value);
                setCardDateOK(isDateValid != "");

                setCardMMAA(isDateValid);

                setCardModalOK( (cardNumberOK!="" && isDateValid!="" && cardCVCOK!="" ) );

              }}/>
            </Form.Item>
            <span
              style={{ display: 'inline-block', width: '40px', lineHeight: '32px', textAlign: 'center' }}
            >
              CVC
            </span>
            <Form.Item style={{ display: 'inline-block', width: 'calc(50% - 20px)' }}>
              <Input  onChange={(e)=>{
                const cvcOK = validateCardCVC(e.target.value);
                setCardCVCOK( cvcOK != "");
                setCardCVC(cvcOK);
                setCardModalOK( (cardNumberOK!="" && cardDateOK!="" && cvcOK!="" ) );

              }} />
            </Form.Item>
          </Form.Item>
        </Form>
      </Modal>

      <Modal title=<span><BsBank />&nbsp;&nbsp;&nbsp;Datos IBAN</span>
        okButtonProps={{ disabled: !sepaModalOK ? "disabled" : undefined }}
        open={showIBANModal} 
        onOk={()=>{
          sendPaymentData({
            "type": "sepa",
            "iban": ibanNumber,
            "holder": ibanHolder
          });

          setShowIBANModal(false);
        }}
        onCancel={() => { setShowIBANModal(false) }}>

        <Form {...formItemLayout} style={{ maxWidth: 600 }}>
          <Form.Item
            label="Número"
            validateTrigger="onBlur"
          >
            <Input
              placeholder="Número IBAN"
              forma
              onChange={(e) => {
                const isValid = validateIBAN(e.target.value);

                setIbanNumber(e.target.value);

                setIbanNumberOK(isValid);

                setSepaModalOK (isValid!="" && ibanHolderOK!="" );
              }}
            />
          </Form.Item>

          <Form.Item
            label="Titula de la cuenta"
            validateTrigger="onBlur"
          >
            <Input
              placeholder="Nombre del titular"
              onChange={(e) => {
                const isValid = validateBankName(e.target.value);

                setIbanHolder(e.target.value);
                setIbanHolderOK(isValid);

                setSepaModalOK (ibanNumberOK!="" && isValid!="" );
              }}
            />
          </Form.Item>



        </Form>
      </Modal>

      <Row>
        <Col span={24}>

            { days_since_subscription_expired>0 &&
                <div>
                <Alert message=<span><b>¡Atenci&oacute;n!</b> No hay una suscripción activa. De no solucionarse, se cancelará la cuenta. <Button onClick={()=>{setShowSubscriptionModal(true)}}>Activar ahora</Button></span> type="error" />
                <br/>
              </div>
            }            

            { default_payment_id==null &&
                <div>
                <Alert message="¡Atenci&oacute;n! No hay ningún método de pago por defecto seleccionado" type="warning" />
                <br/>
              </div>
            }  
            

            <Card>
              <Row>
                <Col span={8}>
                  <Radio.Group defaultValue={payMethod} buttonStyle="solid" onChange={(e) => { setPayMethod(e.target.value); }}>
                    <Radio.Button value="card">Tarjeta</Radio.Button>
                    <Radio.Button value="bank">Banco</Radio.Button>
                  </Radio.Group>
                </Col>
                <Col span={16}>
                    <div style={{float: "right"}}>
                    
                    Opción de pago por defecto:
                    &nbsp;&nbsp;
                    <Select
                        value={defaultPayment}
                        style={{
                          width: 120,
                        }}
                        onChange={(e)=>{ 
                          console.log(e);

                          sendPaymentData({
                            "type": "default_payment",
                            "payment_method": e
                          });

                          setDefaultPayment(e);

                        }}                        
                        options={pay_select_options}
                      />                      

                    </div>
                </Col>
              </Row>              
              

              {(payMethod == "card" && card) &&
                <Card>
                  <table>
                    <tr>
                      <td>
                        {card.card.brand == "visa" ? <RiVisaFill style={{ fontSize: 32 }} /> : <></>}
                        {card.card.brand == "mastercard" ? <TbBrandMastercard style={{ fontSize: 32 }} /> : <></>}

                      </td>
                      <td>Tarjeta {card.card.brand} acabada en: {card.card.last4}</td>
                      <td>Caducidad: {card.card.exp_month} / {card.card.exp_year}</td>
                      <td width={30}></td>
                      <td>
                      <Popconfirm
                            placement="top"
                            title="Confirma que quieres borrar la tarjeta"
                            description="Esta acción no se puede deshacer"
                            onConfirm={(e)=>{                              
                              deletePaymentData("card"); // sepa?
                              props.profileInfo.account_info.payment_info.default_payment=null;
                              setDefaultPayment(null);
                              setPayMethod(null);
                            }}
                            okText="Sí"
                            cancelText="No"
                          >
                          <Button danger>Borrar</Button>
                        </Popconfirm>
                      </td>
                    </tr>
                  </table>

                </Card>
              }

              {(payMethod == "card" && !card) &&
                <Card>
                  <table>
                    <tr>
                      <td>No hay tarjetas configuradas</td>
                      <td width={30}></td>
                      <td><Button onClick={() => { setShowCardModal(true) }}>Añadir</Button></td>
                    </tr>
                  </table>
                </Card>
              }


              {(payMethod == "bank" && sepa) &&
                <Card>
                  <table>
                    <tr>
                      <td>
                        <BsBank />
                      </td>
                      <td>
                        Cuenta: ... {sepa.sepa_debit.last4} a nombre de: {sepa.billing_details.name}
                      </td>
                      <td width={30}></td>
                      <td>
                        <Popconfirm
                              placement="top"
                              title="Confirma que quieres borrar la cuenta"
                              description="Esta acción no se puede deshacer"
                              onConfirm={(e)=>{                              
                                deletePaymentData("sepa_debit"); // sepa?
                                props.profileInfo.account_info.payment_info.default_payment=null;
                                setDefaultPayment(null);
                                setPayMethod(null);
                              }}
                              okText="Sí"
                              cancelText="No"
                            >
                            <Button danger>Borrar</Button>
                          </Popconfirm>
                      </td>
                    </tr>
                  </table>

                </Card>
              }

              {(payMethod == "bank" && !sepa) &&
                <Card>
                  <table>
                    <tr>
                      <td>No hay cuentas IBAN configuradas</td>
                      <td width={30}></td>
                      <td><Button onClick={() => { setShowIBANModal(true) }}>Añadir</Button></td>
                    </tr>
                  </table>
                </Card>
              }


            </Card>

        </Col>
        <Col span={24}><br /></Col>

        <Col span={24}>
          <Card
            title={
              <Space>
                <UnorderedListOutlined />
                Histórico de pagos
              </Space>
            }
            bordered={true}
            style={cardStyle}
            type="inner"
          >
            <Timeline style={{ marginTop: 16 }}>
              {
                paymentHistory.map( (item,index)=>{
                  return(
                      <Timeline.Item>
                        <Text strong style={{ color: '#1890ff' }}>
                          {epoch2date(item.created)}
                        </Text>
                          &nbsp;&nbsp;
                          {item.statement_descriptor}
                          &nbsp;&nbsp;
                          ({`${item.amount_received/100}`}€)
                      </Timeline.Item>
                  )
                    
                })
              }

            </Timeline>
          </Card>
        </Col>
      </Row>
    </div>
  );
};


export default PagosProfileInfoUI;
