import { Tabs } from 'antd';

import PagosProfileInfoUI from './PaymentProfileInfo';
import BaseProfileInfoUI from './BaseProfile';


/**
 * 个人信息 UI 组件
 * @param props
 * @returns
 */

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const cardStyle = {
  marginBottom: 16,
};



const ProfileInfoUI = (props) => {
  const onChange = (key) => {
    //console.log(key);
  };

  const items = [
    {
      key: '1',
      label: `Gestión pagos`,
      children: PagosProfileInfoUI(props),
    },
    {
      key: '2',
      label: `Perfil`,
      children: BaseProfileInfoUI(props),
    }
  ];

  return (
    <Tabs
      style={{ paddingLeft: 20, paddingRight: 20 }}
      defaultActiveKey="1"
      items={items}
      onChange={onChange} />
  )

};




export default ProfileInfoUI;
