import { Button, Form, Input } from 'antd';

import {
  Alert,
  Avatar,
  Card,
  Col,
  Divider,
  List,
  Row,
  Space,
  Table,
  Timeline,
  Typography,
} from 'antd';

import { Select } from 'antd';


import { LayoutForm_wide, LayoutFormButton } from '../../../../config/layout';


const { Text, Link } = Typography;


/**
 * 个人信息 UI 组件
 * @param props
 * @returns
 */

const gridStyle = {
  width: '25%',
  textAlign: 'center',
};

const cardStyle = {
  marginBottom: 16,
};

const dataSource = [
  {
    key: '1',
    role: 'Atención al socio',
    name: '-',
    email: 'atencionalsocio@mensa.es',
  },
  {
    key: '2',
    role: 'Tesorería',
    name: 'tesorería',
    email: 'tesoreria@mensa.es',
  },
  {
    key: '3',
    role: 'Coord. Gipuzkoa',
    name: 'Nora Arrieta',
    email: 'gipuzkoa@mensa.es',
  },
  {
    key: '4',
    role: 'CCG',
    name: 'ccg',
    email: 'ccg@mensa.es'
  },
];

const columns = [
  {
    title: 'Cargo',
    dataIndex: 'role',
    key: 'role',
  },
  {
    title: 'Nombre',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
  }
];

// --- Componente principal -----------------------------------------------------------------

const BaseProfileInfoUI = (props) => {
  const [form] = Form.useForm();


  return (
    <div className="panel-body">

      <Form
        {...LayoutForm_wide}
        name="basic"
        form={form}
        onFinish={props.onFinishCallback}
        initialValues={props.profileInfo?.account_info}
      >
        <Form.Item label="ID" name="account_id" rules={[{ required: true }]}>
          <Input disabled />
        </Form.Item>

        <Form.Item
          label="Nombre"
          name="name"
          rules={[{ required: true, message: 'El nombre es necesario!' }]}
        >
          <Input disabled placeholder="Nombre" />
        </Form.Item>

        <Form.Item
          label="Apellidos"
          name="given_name"
          rules={[{ required: true, message: 'Indicar apellidos' }]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input placeholder="" />
        </Form.Item>

        <Form.Item label="Fecha alta" name="registration_date">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Cuota activa" name="expiration_date">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Sexo" name="sexo">
            <Select
                defaultValue="h"
                style={{
                    width: 120,
                }}
                onChange={(e)=>{}}
                options={[
                    {
                    value: 'h',
                    label: 'Hombre',
                    },
                    {
                    value: 'm',
                    label: 'Mujer',
                    },
                    {
                    value: '-',
                    label: 'Indefinido',
                    }
                ]}
                />
        </Form.Item>

        <Form.Item {...LayoutFormButton}>
            <center>
                <Button type="primary" htmlType="submit">
                    Actualizar
                </Button>

            </center>
        </Form.Item>
      </Form>
    </div>
  );
};



export default BaseProfileInfoUI;
