import {
  UnorderedListOutlined,
  LinkOutlined,
  ContactsOutlined,
} from '@ant-design/icons';

import {message } from 'antd';

import {
  Alert,
  Card,
  Col,
  Row,
  Space,
  Table,
  Timeline,
  Typography,
} from 'antd';

import  { TablePaginationConfig } from 'antd/es/table';
import  { FilterValue, SorterResult } from 'antd/es/table/interface';


import {LoginTokenStore} from "../../../store/local";
import { getUrlConfig } from '../../../config/url';

import { Link } from "react-router-dom";

import { useEffect, useState } from 'react';

import Marquee from 'react-fast-marquee';

import { ProfileService } from '../../../services/Profile';


const { Text } = Typography;


const columns = [
  {
    title: 'Equipo',
    dataIndex: 'Equipo',
    key: 'Equipo',
  },
  {
    title: 'Funciones',
    dataIndex: 'Funciones',
    key: 'Funciones',
  },
  {
    title: 'Nombre',
    dataIndex: 'Nombre',
    key: 'Nombre',
  },  
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'Email',
  }
];






/**
 * 默认主页 UI 组件
 * @param props
 * @returns
 */
const CensoPage = (props) => {
  const [profileInfo, setProfileInfo] = useState({});
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [volunteers, setVolunteers] = useState([]);
  const [data, setData] = useState();
  const [paymentHistory, setpaymentHistory] = useState([]);


  const getPaymentHistory = () => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/profiles/me/get_transactions/`,
      { method: "GET",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        }
        console.log(data);
        setpaymentHistory(data.data);
        setLoadingSpinner(false);
      });
  };  
  

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  
  const handleTableChange = (
    pagination,
    filters,
    sorter,
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getConfigInfo = () => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/config/get_volunteers/`,
      { method: "GET",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
       
        setVolunteers(data.data);
        setLoadingSpinner(false);
  
      });
  };  
  

  // Cuota pagada??
  console.log(props);

  const getProfileInfo = () => {
    ProfileService.getProfileInfo()
      .then((profileInfo) => {

        console.log(profileInfo);
        setProfileInfo(profileInfo);
      })
      .catch(e => {
        console.log('get profile catch: ', e);
      });
  };


  useEffect(() => {
    getProfileInfo();
    getConfigInfo();  
  }, [tableParams]); // Only re-run the effect if count changes
  


  return (
    <div className="panel-body">

        <Table dataSource={volunteers} columns={columns}  />

    </div>
  );
};

export default CensoPage;
