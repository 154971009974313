import { Table } from 'antd';
import { Button, Modal } from 'antd';
import { Radio } from 'antd';

import { useEffect, useState } from 'react';
import { getUrlConfig } from '../../../config/url';
import {LoginTokenStore} from "../../../store/local";

const show_html=(html, row)=> <Button onClick={()=>{ 
    window.modalTitle=row.subject;
    window.modalHtml=html;
    window.openModal(true);
}}>Mostrar</Button>;

const columns = [
  {
    title: 'Título',
    dataIndex: 'subject',
    //sorter: true,
    render: (name) => {return <b>{name}</b>},
    //width: '20%',
  },
  {
    title: 'Tema',
    dataIndex: 'preview_text',
    render: (name, row) => `${row.preview_text||row.subject}`,
    vwidth: '20%',
  },
  {
    title: 'Enlace',
    dataIndex: 'html',    
    render: (html, row) => show_html(html, row)
  }
];
const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});
const InfoMensa = () => {
  const [info_type, setInfoType] = useState("InfoMensa");
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  window.openModal=setOpen;

  const [tableParams, setTableParams] = useState({
    pagination: {
      position: ["topcenter","bottomcenter"],
      showSizeChanger: false,
      current: 1,
      pageSize: 25,
    },
  });

  const fetchData = () => {
    setLoading(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/campaigns/?page=${tableParams.pagination.current}&info_type=${info_type}`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    )
      .then((res) => res.json())
      .then((data) => {
        let results=data.results;

        setData(results);
        setLoading(false);                
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            total: data.total,
          },
        });            


      });
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(tableParams), JSON.stringify(info_type)]);

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };


  let window_height=window.innerHeight*0.75;

  return (
    <section style={{backgroundColor: "rgb(240, 242, 245)", padding: 20}}>    

      <Radio.Group
        options={[{"label": "InfoMensa", "value": "InfoMensa"}, {"label": "CCG", "value": "CCG"}]}
        onChange={(e)=>{  setInfoType(e.target.value) }}
        defaultValue="InfoMensa"
        optionType="button"
        buttonStyle="solid"
      />

      <Modal
        title=<center>{window.modalTitle}</center>
        centered
        open={open}
        onCancel={() => setOpen(false)}
        onOk={() => setOpen(false)}
        width={1000}
        bodyStyle={{height: window_height, padding: 0, margin: 0}}
        cancelButtonProps={{ style: { display: 'none' } }}
      >

          <iframe id="" 
            name="infomensa_iframe" sandbox="allow-same-origin" 
            style={{width: "100%", height: "100%"}}
            srcDoc={window.modalHtml} 
          />

      </Modal>

      
      <Table
        columns={columns}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={tableParams.pagination}
        loading={loading}        
        onChange={handleTableChange}
      />
    </section>
  );
};
export default InfoMensa;