import {
  UnorderedListOutlined,
  LinkOutlined,
  HddOutlined,
  ContactsOutlined,
  ReadOutlined,
  DesktopOutlined,
  ChromeOutlined,
  CodeOutlined,
} from '@ant-design/icons';
import {message } from 'antd';

import {
  Alert,
  Avatar,
  Card,
  Col,
  Divider,
  List,
  Row,
  Space,
  Table,
  Timeline,
  Typography,
} from 'antd';

import 'moment/locale/es';
import moment from "moment";

import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';


import {LoginTokenStore} from "../../../store/local";
import { getUrlConfig } from '../../../config/url';

import { Link } from "react-router-dom";

import { useEffect, useState } from 'react';

import Marquee from 'react-fast-marquee';

import { ProfileService } from '../../../services/Profile';


moment.locale("es", {
  week: {
    dow: 1
  }
});

const { Text } = Typography;

interface MainIndexUIProps {}

interface Activity{
  dia: number;
}

interface TableParams {
  pagination?: TablePaginationConfig;
  sortField?: string;
  sortOrder?: string;
  filters?: Record<string, FilterValue>;
}

const epoch2date=(what:any)=>{
  let date=(new Date(what*1000));

  return date.getDate()+"/"+(date.getMonth()+1)+"/"+date.getFullYear() + "  " + date.getHours() + ":" + date.getMinutes()
}


interface DataType {
  Equipo: string;
  Funciones: string;
  Nombre: string;
  Email: string;
}

const columns = [
  {
    title: 'Email',
    dataIndex: 'Email',
    key: 'Email',
  },
  {
    title: 'Funciones',
    dataIndex: 'Funciones',
    key: 'Funciones',
  },
];

const gridStyle: React.CSSProperties = {
  width: '25%',
  textAlign: 'center',
};

const cardStyle: React.CSSProperties = {
  marginBottom: 16,
};





/**
 * 默认主页 UI 组件
 * @param props
 * @returns
 */
const MainIndexUI = (props: MainIndexUIProps) => {
  const [profileInfo, setProfileInfo] = useState<any>({});
  const [loadingSpinner, setLoadingSpinner] = useState(false);
  const [emails, setEmails] = useState([]);
  const [data, setData] = useState<DataType[]>();
  const [paymentHistory, setpaymentHistory] = useState([]);

  const [activityData, setActivityData] = useState<Activity[]>([]);

  const fetchActivityData = (month: number,year: number) => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();

    fetch(`${getUrlConfig().proxyUrl}/activities/?year=${year}&month=${month+1}`,
      {headers: {
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        let results=data.results;
        console.log(results);
        setActivityData(results);
        setLoadingSpinner(false);

      });
  };  

  const getPaymentHistory = () => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/profiles/me/get_transactions/`,
      { method: "GET",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
        if (data.error) {
          message.error(data.error);
        }
        console.log(data);
        setpaymentHistory(data.data);
        setLoadingSpinner(false);
      });
  };  
  

  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  
  const handleTableChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue>,
    sorter: SorterResult<DataType>,
  ) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
  };

  const getConfigInfo = () => {
    setLoadingSpinner(true);
    const loginToken = LoginTokenStore.getToken();
    fetch(`${getUrlConfig().proxyUrl}/config/get_emails/`,
      { method: "GET",
        headers: {
        "Content-Type": "application/json",
        "authorization": `token ${loginToken}`
      }
    }    
    ).then((res) => res.json())
      .then((data) => {
       
        setEmails(data.data);
        setLoadingSpinner(false);
  
      });
  };  
  

  // Cuota pagada??
  console.log(props);

  const getProfileInfo = () => {
    ProfileService.getProfileInfo()
      .then((profileInfo) => {

        console.log(profileInfo);
        setProfileInfo(profileInfo);
      })
      .catch(e => {
        console.log('get profile catch: ', e);
      });
  };


  const year=(new Date()).getFullYear();
  const month=(new Date()).getMonth()+1;

  useEffect(() => {
    getProfileInfo();
    getConfigInfo();  
    getPaymentHistory();
    fetchActivityData(month, year);
  }, [tableParams]); // Only re-run the effect if count changes
  
  let alert_message= <div className="panel-body">
                                <Alert
                                  showIcon
                                  type="info"
                                  message={
                                    <Marquee pauseOnHover gradient={false} speed={60}>
                                      ¡ No has pagado tu cuota! 
                                      &nbsp;&nbsp;&nbsp; Más info en tu &nbsp;
                                      <Link to="/profile/info"><LinkOutlined />Tu zona de perfil</Link>
                                    </Marquee>
                                  }
                                />
                              </div>;

  if (profileInfo.account_info!==undefined) {
    if(profileInfo.account_info.payment_info.days_since_subscription_started <3){
      alert_message=<></>;
    }
    
  }


  return (
    <div>

      {alert_message}

      <div className="panel-body">
        <Row gutter={16}>
          <Col span={16}>
            <Card
              title={
                <Space>
                  <UnorderedListOutlined />
                  Histórico de pagos
                </Space>
              }
              bordered={true}
              style={cardStyle}
              type="inner"
            >
                <Timeline style={{ marginTop: 16 }}>
                  {
                    paymentHistory.map( (item: any,index)=>{
                      return(
                          <Timeline.Item>
                            <Text strong style={{ color: '#1890ff' }}>
                              {epoch2date(item.created)}
                            </Text>
                              &nbsp;&nbsp;
                              {item.statement_descriptor}
                              &nbsp;&nbsp;
                              ({`${item.amount_received/100}`}€)
                          </Timeline.Item>
                      )
                        
                    })
                  }

                </Timeline>
            </Card>
          </Col>
          <Col span={8}>
            <Card
              title={
                <Space>
                  <LinkOutlined />
                  Blog Mensa
                </Space>
              }
              style={cardStyle}
              type="inner"
            >
              <Row>
                <Col span={24} className="text-center" style={{ marginBottom: 12 }}>
                  Abril-2023: <a href="">InfoMensa 258</a>
                </Col>                
                <Col span={24} className="text-center" style={{ marginBottom: 12 }}>
                  Marzo-2023: <a href="">Nuevo aviso de la CCG</a>
                </Col>
              </Row>
            </Card>

          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Card
                title={
                  <Space>
                    <ContactsOutlined />
                      Actividades hoy
                  </Space>
                }
                bordered={true}
                type="inner"
                style={cardStyle}
                bodyStyle={{ padding: 16 }}
              >
                {
                  activityData.filter( z => (z.dia== (new Date()).getDate() )).map( (item:any,index: number)=>{
                      return (
                        <div>
                            {index>0 && <hr/>}
                            <b>{item.title}</b><br/>                            
                            <span dangerouslySetInnerHTML={{__html: item.text}}></span>
                        </div>
                      )
                  })
                }

                {
                  activityData.filter( z => (z.dia ==(new Date().getDate()))).length==0 &&
                  <span>No hay actividades planificadas para hoy</span>
                }

              </Card>          
          </Col>
        </Row>        

        <Row>
          <Col span={24}>
            <Card
                title={
                  <Space>
                    <ContactsOutlined />
                      Direcciones de interés
                  </Space>
                }
                bordered={true}
                type="inner"
                style={cardStyle}
                bodyStyle={{ padding: 16 }}
              >
                <Table dataSource={emails} columns={columns} pagination={tableParams.pagination} />
              </Card>          
          </Col>
        </Row>

      </div>
    </div>
  );
};

export default MainIndexUI;
